@tailwind base;
@tailwind components;
@tailwind utilities;

.transition-all {
  transition: all 0.3s ease;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-line-height {
  height: 0.5px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-dots {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}
.custom-dots li button:before {
  color: white !important;
}
